import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import StoreIcon from "@mui/icons-material/Store";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import {
  Button,
  useMediaQuery,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import ServicesIcon from "@mui/icons-material/Inventory";
import PortfolioIcon from "@mui/icons-material/LocalLibrary";
import BiographyIcon from "@mui/icons-material/Person";
import PricingIcon from "@mui/icons-material/LocalOffer";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import InsightsIcon from '@mui/icons-material/Insights';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Link as RouterLink } from "react-router-dom";
import { NavLink } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const avatars = [
    {
      icon: <StoreIcon />,
      link: "https://g.page/r/CTH6koc4rO-BEBM/",
      caption: "Google Store",
    },
    {
      icon: <PhoneIcon />,
      link: "tel:0718768263",
      caption: "Phone",
    },
    {
      icon: <EmailIcon />,
      link: "mailto:michael@moveonline.co.za",
      caption: "Email",
    },
    {
      icon: <WhatsAppIcon />,
      link: "https://wa.me/27718768263",
      caption: "Whatsapp",
    },
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/moveonlinesolutions",
      caption: "Facebook",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/move_online_solutions",
      caption: "Instagram",
    },
  ];

  const drawerItems = [
    {
      text: "Services",
      path: "/services",
      icon: <ServicesIcon />,
    },
    {
      text: "Statistics",
      path: "/statistics",
      icon: <InsightsIcon />,
    },
    {
      text: "Portfolio",
      path: "/portfolio",
      icon: <PortfolioIcon />,
    },
    {
      text: "Biography",
      path: "/biography",
      icon: <BiographyIcon />,
    },
    {
      text: "Pricing",
      path: "/pricing",
      icon: <PricingIcon />,
    },
    {
      text: "FAQ",
      path: "/FAQ",
      icon: <LiveHelpIcon />,
    },
    {
      text: "Contact Us",
      path: "/contact",
      icon: <ConnectWithoutContactIcon />,
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listItems, setListItems] = React.useState([]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    // Generate your list items here
    setListItems(avatars);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    console.log("Clicked:", item);
    // Perform any action you want based on the clicked item
    // For example, navigate to a different page or execute a function
    // You can close the menu here if needed
    handleMenuClose();
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    window.scrollTo(0, 0);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <RouterLink to="/" style={{ color:"#ffffff", textDecoration:"none" }}>
          <Typography variant="h6" noWrap component="div" onClick={handleDrawerClose}>
            MOVE ONLINE
          </Typography>
            </RouterLink>

          {isSmallScreen ? (
            <Box sx={{ marginLeft: "auto" }}>
              <Button onClick={handleMenuOpen} variant="outlined" color="info" aria-label="Open Links">
                <ConnectWithoutContactIcon fontSize="large" />
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {listItems.map((avatar, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuItemClick(avatar)}
                  >
                     
                    <Link
                      href={avatar.link}
                      underline="none"
                      color={index % 2 === 0 ? "primary.main": "secondary.main"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {avatar.icon}
                      <Typography variant="body1">{avatar.caption}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Box sx={{ marginLeft: "auto" }}>
              <AvatarGroup max={7}>
                {avatars.map((avatar, index) => (
                  <Avatar key={index} sx={{ backgroundColor: index % 2 === 0 ? "secondary.main": "silver" }}>
                    <Link href={avatar.link} underline="none" color="inherit">
                      {avatar.icon}
                    </Link>
                  </Avatar>
                ))}
              </AvatarGroup>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: "primary.main" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "white" }} fontSize="large"/>
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} fontSize="large" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {drawerItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <NavLink to={item.path} style={{textDecoration:"none"}} onClick={handleDrawerClose} >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: index % 2 === 0 ? "primary.main": "secondary.main",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0, color:  "primary.main" }}
                />
              </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
          <NavLink to='/blog' style={{textDecoration:"none"}} onClick={handleDrawerClose} >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "primary.main",
                }}
              >
                <RssFeedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Blog"
                sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
              />
            </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box>
    </Box>
  );
}
