import './App.css';
import TopAppBar from './components/TopAppBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import { Suspense } from 'react';
import Loading from './components/Loading.js';
import {HelmetProvider} from 'react-helmet-async';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
const HomePage = lazy(() => import('./components/pages/HomePage.js'));
const ServicesPage = lazy(() => import('./components/pages/ServicesPage.js'));
const StatisticsPage = lazy(() => import('./components/pages/StatisticsPage.js'));
const PortfolioPage = lazy(() => import('./components/pages/PortfolioPage.js'));
const BiographyPage = lazy(() => import('./components/pages/BiographyPage.js'));
const PricingPage = lazy(() => import('./components/pages/PricingPage.js'));
const ContactMePage = lazy(() => import('./components/pages/ContactMePage.js'));
const BlogHome = lazy(() => import('./components/blog/BlogHome.js'));
const FAQ = lazy(() => import('./components/pages/FAQPage.js'));









const theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#009688',
    },
    info: {
      main: '#ffffff', // Change this to your desired color
    },
  },
});

function App() {
  return (
    
      <ThemeProvider theme={theme}>
        <HelmetProvider>

      <TopAppBar/>
      <div class="rest">
      <Suspense fallback={<Loading page="Welcome to Move: Online Solutions"/>}>
      <Routes>
      
      <Route path='/' element={<HomePage/>}/>
      <Route path='/services' element={<ServicesPage/>}/> 
      <Route path='/statistics' element={<StatisticsPage/>}/>
      <Route path='/portfolio' element={<PortfolioPage/>}/>
      <Route path='/biography' element={<BiographyPage/>}/>  
      <Route path='/pricing' element={<PricingPage/>}/>  
      <Route path='/FAQ' element={<FAQ/>}/>  
      <Route path='/contact' element={<ContactMePage/>}/>  
      <Route path='/blog' element={<BlogHome/>}/>  
      
      </Routes>
      </Suspense>
        
      </div>
      </HelmetProvider>
      
      </ThemeProvider>
     
  );
}

export default App;
