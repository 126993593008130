import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function Loading(props) {
  return (
    <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={8}>
        <Typography variant="h3" textAlign="center" color="secondary.main" gutterBottom>{props.page}</Typography>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
        </Grid>
        
    </Grid>
  );
}